<script setup lang="ts">
import qs from 'qs';
import systemInfo from '@/stores/systemInfo';
import { checkUpdated } from '@utils/methods';
import { SYSTEM_INFO } from '@config/constant';
import { onLaunch, onShow } from '@dcloudio/uni-app';

const sysInfo = systemInfo();

const referralBindingStore = useReferralBindingStore();

sysInfo.$subscribe((_, state) =>
	uni.setStorage({
		key: SYSTEM_INFO,
		data: state,
	})
);

onLaunch(async ({ query }: any) => {
	sysInfo.setSysData(query);
});

onShow(({ query }: any) => {
	checkUpdated();
	referralBindingStore.setCode(query);

	if (query.scene) {
		let scene: any = decodeURIComponent(query.scene);
		scene = qs.parse(scene);
		referralBindingStore.setCode(scene);
	}
});
</script>

<style lang="scss">
@import '@climblee/uv-ui/index.scss';

@import '@/assets/styles/public.scss';
@import '@/assets/styles/basic-form.scss';

</style>
