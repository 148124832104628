export const useReferralBindingStore = defineStore('referralBinding', {
	state: () => ({
		mid: '',
		inviteCode: '',
	}),
	actions: {
		async bindReferral() {
			// #ifdef MP
			const { mid, inviteCode } = this.$state;
			if (mid || inviteCode) {
				const { code } = await uni.login({ provider: 'weixin', onlyAuthorize: true });
				await request.post({
					resErr: true,
					showLoad: false,
					port: port.MEMBER_BINDMEMBER,
					params: this.setParams({ code }),
				});
			}
			// #endif
		},
		async setCode({ mid, inviteCode }: any = {}) {
			mid && (this.mid = mid);
			inviteCode && (this.inviteCode = inviteCode);
			await this.bindReferral();
		},
		setParams(params: any = {}) {
			params = cloneDeep(unref(params));
			merge(params, this.$state);
			return params;
		},
	},
});
