enum port {
	// Agreement
	AGREEMENT_GETAGREEMENTDETAIL = 'Agreement.GetAgreementDetail',

	// Sms
	SMS_SEND = 'Sms.Send',
	SMS_SENDSMS = 'Sms.SendSms',

	// Nav
	NAV_GETNAVLIST = 'Nav.GetNavList',

	// Wares
	WARES_GETCATELIST = 'Wares.GetCateList',
	WARES_GETWARESDETAIL = 'Wares.GetWaresDetail',
	WARES_POSTCART = 'Wares.PostCart',
	WARES_POSTCOLLECT = 'Wares.PostCollect',
	WARES_GETCARTLIST = 'Wares.GetCartList',
	WARES_DELETECART = 'Wares.DeleteCart',
	WARES_GETOPTIONLIST = 'Wares.GetOptionList',
	WARES_CREATEORDERPRE = 'Wares.CreateOrderPre',
	WARES_SELECTCART = 'Wares.SelectCart',
	WARES_CREATEORDER = 'Wares.CreateOrder',
	WARES_GETORDERLIST = 'Wares.GetOrderList',
	WARES_CANCELORDER = 'Wares.CancelOrder',
	WARES_FINISHORDER = 'Wares.FinishOrder',
	WARES_GETORDERDETAIL = 'Wares.GetOrderDetail',
	WARES_GETHOTLIST = 'Wares.GetHotList',
	WARES_GETWARESLIST = 'Wares.GetWaresList',
	WARES_GETCOLLECTLIST = 'Wares.GetCollectList',
	WARES_GETAREADETAIL = 'Wares.GetAreaDetail',
	WARES_GETMODELIST = 'Wares.GetModelList',
	WARES_GETMSGLIST = 'Wares.GetMsgList',
	WARES_CREATEMSG = 'Wares.CreateMsg',
	WARES_CHNAGEWARES = 'Wares.ChangeWares',
	WARES_GETAREALIST = 'Wares.GetAreaList',
	WARES_GETSIZELIST = 'Wares.GetSizeList',
	WARES_GETPATTERNLIST = 'Wares.GetPatternList',
	WARES_GETAPPLIANCELIST = 'Wares.GetApplianceList',
	WARES_GETYEARLIST = 'Wares.GetYearList',
	WARES_GETNEWLIST = 'Wares.GetNewList',
	WARES_POSTWARES = 'Wares.PostWares',
	WARES_GETBRANDLIST = 'Wares.GetBrandList',
	WARES_GETBRANDDETAIL = 'Wares.GetBrandDetail',
	WARES_POSTBRANDCOLLECT = 'Wares.PostBrandCollect',
	WARES_GETBRANDCOLLECTLIST = 'Wares.GetBrandCollectList',
	WARES_GETVIEWLIST = 'Wares.GetViewList',
	WARES_GETORDERCODEDETAIL = 'Wares.GetOrderCodeDetail',
	WARES_WRITEORDERCODE = 'Wares.WriteOrderCode',
	WARES_CREATEORDERCOMMENT = 'Wares.CreateOrderComment',
	WARES_GETORDERCOMMENTLIST = 'Wares.GetOrderCommentList',
	WARES_GETCOMMENTLIST = 'Wares.GetCommentList',
	WARES_GETCOMMENTDETAIL = 'Wares.GetCommentDetail',

	// System
	SYSTEM_GETSET = 'System.GetSet',
	SYSTEM_UPLOAD = 'System.Upload',
	SYSTEM_GETSETDATA = 'System.GetSetData',

	// Pay
	PAY_GETPAYLIST = 'Pay.GetPayList',
	PAY_PAYORDER = 'Pay.PayOrder',
	PAY_CHECKSTATUS = 'Pay.CheckStatus',

	// Wechat
	WECHAT_CODETOPENID = 'Wechat.CodeToOpenid',
	WECHAT_GETMPMOBILE = 'Wechat.GetMpMobile',
	WECHAT_LOGIN = 'Wechat.Login',

	// Share
	SHARE_GETSHAREDATA = 'Share.GetShareData',

	// Kitchen
	KITCHEN_GETCATELIST = 'Kitchen.GetCateList',
	KITCHEN_GETKITCHENLIST = 'Kitchen.GetKitchenList',
	KITCHEN_GETKITCHENDETAIL = 'Kitchen.GetKitchenDetail',

	// Question
	QUESTION_GETCATELIST = 'Question.GetCateList',
	QUESTION_GETQUESTIONLIST = 'Question.GetQuestionList',
	QUESTION_GETQUESTIONDETAIL = 'Question.GetQuestionDetail',

	// Expert
	EXPERT_GETEXPERTLIST = 'Expert.GetExpertList',
	EXPERT_GETEXPERTDETAIL = 'Expert.GetExpertDetail',

	// Region
	REGION_GETREGIONLIST = 'Region.GetRegionList',
	REGION_DISTINGUISHADDRESS = 'Region.DistinguishAddress',

	// Member
	MEMBER_POSTADDRESS = 'Member.PostAddress',
	MEMBER_GETADDRESSDETAIL = 'Member.GetAddressDetail',
	MEMBER_DELETEADDRESS = 'Member.DeleteAddress',
	MEMBER_SETDEFAULTADDRESS = 'Member.SetDefaultAddress',
	MEMBER_GETADDRESSLIST = 'Member.GetAddressList',
	MEMBER_GETMEMBERDETAIL = 'Member.GetMemberDetail',
	MEMBER_CHANGEINFO = 'Member.ChangeInfo',
	MEMBER_CHANGEAVATAR = 'Member.ChangeAvatar',
	MEMBER_CHANGEPWD = 'Member.ChangePwd',
	MEMBER_CHECKMEMBER = 'Member.CheckMember',
	MEMBER_BINDMEMBER = 'Member.BindMember',
	MEMBER_GETRECOMMENDDATA = 'Member.GetRecommendData',
	MEMBER_GETRECOMMENDLIST = 'Member.GetRecommendList',
	MEMBER_GETDEFAULTADDRESS = 'Member.GetDefaultAddress',
	MEMBER_LOGIN = 'Member.Login',
	MEMBER_CHNAGEMOBILE = 'Member.ChangeMobile',
	MEMBER_LOGOUT = 'Member.Logout',

	// Tab
	TAB_GETTABLIST = 'Tab.GetTabList',

	// Poster
	POSTER_CREATEPOSTER = 'Poster.CreatePoster',

	// Version
	VERSION_GETVERSION = 'Version.GetVersion',
	VERSION_GETDOWNLOADDATA = 'Version.GetDownloadData',

	// Credit
	CREDIT_CREATERECHARGE = 'Credit.CreateRecharge',
	CREDIT_GETLOGLIST = 'Credit.GetLogList',
	CREDIT_CREATEGIVE = 'Credit.CreateGive',
	CREDIT_CREATEWITHDRAW = 'Credit.CreateWithdraw',
	CREDIT_GETBANKLIST = 'Credit.GetBankList',
	CREDIT_CREATETRANSFER = 'Credit.CreateTransfer',
	CREDIT_GETCOLLECTIONDETAIL = 'Credit.GetCollectionDetail',
	CREDIT_UPDATETRANSFER = 'Credit.UpdateTransfer',
	CREDIT_GETTRANSFERDETAIL = 'Credit.GetTransferDetail',
	CREDIT_GETTRANSFERDLIST = 'Credit.GetTransferList',
	CREDIT_GETPAYMENTLIST = 'Credit.GetPaymentList',
	CREDIT_POSTPAYMENT = 'Credit.PostPayment',
	CREDIT_GETPAYMENTDETAIL = 'Credit.GetPaymentDetail',
	CREDIT_GETGIVERULE = 'Credit.GetGiveRule',
	CREDIT_GETWITHDRAWDATA = 'Credit.GetWithdrawData',
	CREDIT_GETWITHDRAWRULE = 'Credit.GetWithdrawRule',
	CREDIT_GETWITHDRAWLIST = 'Credit.GetWithdrawList',
	CREDIT_GETEXCHANGERULE = 'Credit.GetExchangeRule',
	CREDIT_CREATEEXCHANGE = 'Credit.CreateExchange',
	CREDIT_GETGIVEDATA = 'Credit.GetGiveData',

	// Stock
	STOCK_GETSTOCKLIST = 'Stock.GetStockList',
	STOCK_GETLOGLIST = 'Stock.GetLogList',
	STOCK_CREATEGIVE = 'Stock.CreateGive',
	STOCK_GETSTOCKDETAIL = 'Stock.GetStockDetail',

	// Agent
	AGENT_GETCARTLIST = 'Agent.GetCartList',
	AGENT_POSTCART = 'Agent.PostCart',
	AGENT_CREATEORDER = 'Agent.CreateOrder',
	AGENT_GETORDERLIST = 'Agent.GetOrderList',
	AGENT_CANCELORDER = 'Agent.CancelOrder',
	AGENT_GETORDERDETAIL = 'Agent.GetOrderDetail',
	AGENT_GETMARGINLIST = 'Agent.GetMarginList',
	AGENT_CREATEMARGINREFUND = 'Agent.CreateMarginRefund',
	AGENT_CREATEMARGINRECHARGE = 'Agent.CreateMarginRecharge',
	AGENT_FINISHORDER = 'Agent.FinishOrder',

	// Order
	ORDER_GETEXPRESSLIST = 'Order.GetExpressList',

	// Express
	EXPRESS_GETLOGISTICSLIST = 'Express.GetLogisticsList',
	EXPRESS_GETEXPRESSLIST = 'Express.GetExpressList',

	// Code
	CODE_CREATEORDER = 'Code.CreateOrder',
	CODE_CHECKBOXLOG = 'Code.CheckBoxLog',
	CODE_GETORDERLIST = 'Code.GetOrderList',
	CODE_GETBOXDATA = 'Code.GetBoxData',
	CODE_GETCURRENTYLOG = 'Code.GetCurrentLog',
	CODE_GETLOGLIST = 'Code.GetLogList',
	CODE_GETCODEDATA = 'Code.GetCodeData',
	CODE_GETORDERDETAIL = 'Code.GetOrderDetail',

	// School
	SCHOOL_GETCATELIST = 'School.GetCateList',
	SCHOOL_GETSCHOOLDETAIL = 'School.GetSchoolDetail',
	SCHOOL_GETSCHOOLLIST = 'School.GetSchoolList',

	// Invest
	INVEST_CREATEINVEST = 'Invest.CreateInvest',
	INVEST_GETINVESTLIST = 'Invest.GetInvestList',
	INVEST_CANCELINVEST = 'Invest.CancelInvest',

	// Notice
	NOTICE_GETNOTICELIST = 'Notice.GetNoticeList',
	NOTICE_GETNOTICEDETAIL = 'Notice.GetNoticeDetail',

	// Vip
	VIP_CREATELOG = 'Vip.CreateLog',
	VIP_BONUS = 'Vip.Bonus',
	VIP_GETTODAYDATA = 'Vip.GetTodayData',
	VIP_POSTLIMIT = 'Vip.PostLimit',
	VIP_CREATENOTIFY = 'Vip.CreateNotify',

	// Playlet
	PLAYLET_POSTHISTORY = 'Playlet.PostHistory',
	PLAYLET_GETPLAYETLIST = 'Playlet.GetPlayletList',
	PLAYLET_POSTCOLLECT = 'Playlet.PostCollect',
	PLAYLET_POSTGOOD = 'Playlet.PostGood',
	PLAYLET_GETEPISODEDETAIL = 'Playlet.GetEpisodeDetail',
	PLAYLET_GETEPISODELIST = 'Playlet.GetEpisodeList',

	// Third
	THIRD_LOGIN = 'Third.Login',
	THIRD_BINDMOBILE = 'Third.BindMobile',
	THIRD_UNBIND = 'Third.Unbind',
	THIRD_GETBINDDATA = 'Third.GetBindData',

	// Uni
	UNI_GETTOKEN = 'Uni.GetToken',

	// Msg
	MSG_GETMSGDATA = 'Msg.GetMsgData',

	// Cert
	CERT_CREATECERT = 'Cert.CreateCert',
	CERT_GETCERTDATA = 'Cert.GetCertData',

	//Merchant
	MERCHANT_GETSET = 'Merchant.GetSet',
	MERCHANT_POSTAPPLY = 'Merchant.PostApply',
	MERCHANT_GETAPPLYDETAIL = 'Merchant.GetApplyDetail',
	MERCHANT_GETAPPLYDATA = 'Merchant.GetApplyData',
	MERCHANT_LOGIN = 'Merchant.Login',
	MERCHANT_GETCATELIST = 'Merchant.GetCateList',
	MERCHANT_CHANGEPWD = 'Merchant.ChangePwd',
	MERCHANT_GETSCHOOLLIST = 'Merchant.GetSchoolList',
	MERCHANT_GETSCHOOLDETAIL = 'Merchant.GetSchoolDetail',
	MERCHANT_GETMERCHANTDETAIL = 'Merchant.GetMerchantDetail',
	MERCHANT_CHANGEMERCHANT = 'Merchant.ChangeMerchant',
	MERCHANT_GETDISPATCHLIST = 'Merchant.GetDispatchList',
	MERCHANT_GETDISPATCHDETAIL = 'Merchant.GetDispatchDetail',
	MERCHANT_DELETEDISPATCH = 'Merchant.DeleteDispatch',
	MERCHANT_SETDEFAULTDISPATCH = 'Merchant.SetDefaultDispatch',
	MERCHANT_GETDEFAULTDISPATCH = 'Merchant.GetDefaultDispatch',
	MERCHANT_POSTDISPATCH = 'Merchant.PostDispatch',
	MERCHANT_GETDEFAULTADDRESS = 'Merchant.GetDefaultAddress',
	MERCHANT_GETADDRESSLIST = 'Merchant.GetAddressList',
	MERCHANT_GETADDRESSDETAIL = 'Merchant.GetAddressDetail',
	MERCHANT_SETDEFAULTADDRESS = 'Merchant.SetDefaultAddress',
	MERCHANT_DELETEADDRESS = 'Merchant.DeleteAddress',
	MERCHANT_POSTADDRESS = 'Merchant.PostAddress',
	MERCHANT_CHANGEMOBILE = 'Merchant.ChangeMobile',
	MERCHANT_REMARKORDER = 'Merchant.RemarkOrder',
	MERCHANT_ADDRESSORDER = 'Merchant.AddressOrder',
	MERCHANT_PRICEORDER = 'Merchant.PriceOrder',
	MERCHANT_SENDORDER = 'Merchant.SendOrder',
	MERCHANT_GETORDEREXPRESS = 'Merchant.GetOrderExpress',
	MERCHANT_CANCELSEND = 'Merchant.CancelSend',
	MERCHANT_GETMERCHANTLIST = 'Merchant.GetMerchantList',
	MERCHANT_GETCATEDETAIL = 'Merchant.GetCateDetail',
	MERCHANT_CHANGECOMMENTSTATUS = 'Merchant.ChangeCommentStatus',
	MERCHANT_REPLYCOMMENT = 'Merchant.ReplyComment',

	// Feedback
	FEEDBACK_GETCATELIST = 'Feedback.GetCateList',
	FEEDBACK_CREATEFEEDBACK = 'Feedback.CreateFeedback',

	// Faq
	FAQ_GETCATELIST = 'Faq.GetCateList',
	FAQ_GETFAQLIST = 'Faq.GetFaqList',
	FAQ_GETFAQDETAIL = 'Faq.GetFaqDetail',

	// Wechat
	WECHAT_BIND = 'Wechat.Bind',

	// Sign
	SIGN_CREATELOG = 'Sign.CreateLog',
	SIGN_GETLOGLIST = 'Sign.GetLogList',

	// Yop
	YOP_GETAPPLYDATA = 'Yop.GetApplyData',
	YOP_GETAPPLYDETAIL = 'Yop.GetApplyDetail',
	YOP_GETBANKLIST = 'Yop.GetBankList',
	YOP_POSTAPPLY = 'Yop.PostApply',
}

export default port;
