// tabbar
export const tabbar: string[] = [
	'/pages/home',
	// '/pages/goods/list',
	// '/pages/invest/index',
	// '/pages/order/cart',
	// '/pages/wallet/index',
	'/pages/mycenter/index',
	// '/pages/community/index',
	// '/pages/playlet/follow',
	'/pages/message/index',
	'/pages/intracity/index',
];

// 原生
export const native: string[] = [
	...tabbar,
	'/pages/community/index',
	'/pages/community/expert',
	'/pages/community/kitchen',
	'/pages/community/question',
	'/pages/community/school',
	'/pages/goods/list',
	'/pages/goods/detail',
	'/pages/goods/anti-fake',
	'/pages/goods/search/list',
	'/pages/goods/search/index',
	'/pages/goods/cate',
	'/pages/goods/area',
	'/pages/goods/area-list',
	'/pages/mycenter/login/index',
	'/pages/mycenter/login/register',
	'/pages/mycenter/login/bind-mobile',
	'/pages/mycenter/collect',
	'/pages/mycenter/footprint',
	'/pages/mycenter/agreement',
	'/pages/mycenter/signIn',
	'/pages/order/list',
	'/pages/order/app-pay',
	'/pages/order/detail',
	'/pages/order/submit',
	'/pages/order/cart',
	'/pages/order/subcart',
	'/pages/order/express/list',
	'/pages/order/express/detail',
	'/pages/order/comment/list',
	'/pages/order/comment/detail',
	'/pages/setting/index',
	'/pages/setting/pwd',
	'/pages/setting/mobile',
	'/pages/setting/agreement',
	'/pages/setting/bind',
	'/pages/setting/location/edit',
	'/pages/setting/location/index',
	'/pages/agent/index',
	'/pages/agent/client',
	'/pages/agent/invite',
	'/pages/agent/earnest',
	'/pages/agent/scan/index',
	'/pages/agent/scan/freight',
	'/pages/agent/scan/tracing',
	'/pages/agent/scan/record',
	'/pages/agent/scan/detail',
	'/pages/agent/inventory/list',
	'/pages/agent/inventory/detail',
	'/pages/agent/inventory/deliver',
	'/pages/agent/order/cart',
	'/pages/agent/order/list',
	'/pages/agent/order/detail',
	'/pages/wallet/index',
	'/pages/wallet/income',
	'/pages/wallet/record',
	'/pages/wallet/transfer',
	'/pages/wallet/withdraw/index',
	'/pages/wallet/withdraw/record',
	'/pages/wallet/recharge/index',
	'/pages/wallet/recharge/record',
	'/pages/wallet/exchange',
	'/pages/wallet/setting/index',
	'/pages/wallet/setting/wechat',
	'/pages/wallet/setting/bankcard',
	'/pages/wallet/setting/alipay',
	'/pages/wallet/earnings',
	'/pages/invest/index',
	'/pages/invest/record',
	'/pages/notice/index',
	'/pages/notice/detail',
	'/pages/playlet/detail',
	'/pages/setting/download',
	'/pages/shop/apply',
	'/pages/shop/login',
	'/pages/shop/auth/index',
	'/pages/shop/auth/company',
	'/pages/shop/auth/person',
	'/pages/shop/manage/index',
	'/pages/shop/manage/edit',
	'/pages/shop/manage/menu',
	'/pages/shop/manage/feedback',
	'/pages/shop/order/list',
	'/pages/shop/order/detail',
	'/pages/shop/order/address',
	'/pages/shop/order/send',
	'/pages/shop/school/index',
	'/pages/shop/school/detail',
	'/pages/shop/goods/manage',
	'/pages/shop/redemption',
	'/pages/shop/dispatch/edit',
	'/pages/shop/dispatch/list',
	'/pages/shop/address/edit',
	'/pages/shop/address/list',
	'/pages/shop/setting/index',
	'/pages/shop/setting/pwd',
	'/pages/shop/comment/list',
	'/pages/shop/comment/detail',
	'/pages/shop/yop/apply',
	'/pages/shop/yop/detail',
	'/pages/services/cert',
	'/pages/services/help/list',
	'/pages/services/help/detail',
	'/pages/brand/index',
	'/pages/brand/detail',
	'/pages/brand/search',
	'/pages/brand/image',
	'/pages/intracity/index',
	'/pages/intracity/goods/list',
	'/pages/intracity/goods/detail',
	'/pages/intracity/order/list',
	'/pages/intracity/order/detail',
	'/pages/intracity/shop/list',
	'/pages/intracity/shop/detail',
	'/pages/intracity/shop/info',
	'/pages/goods/edit/entrance',
	'/pages/goods/edit/cate',
	'/pages/goods/edit/intracity',
	'/pages/goods/edit/tire',
	'/pages/goods/edit/normal',
];

// 不需要登录
export const noLogin: string[] = [
	'/pages/mycenter/login/index',
	'/pages/mycenter/login/register',
	'/pages/mycenter/login/bind-mobile',
	'/pages/setting/agreement',
	'/pages/setting/download',
];

// 需要登录
export const needLogin: string[] = [
	'/pages/mycenter/collect',
	'/pages/mycenter/footprint',
	'/pages/mycenter/signIn',
	'/pages/order/list',
	'/pages/order/cart',
	'/pages/order/detail',
	'/pages/order/submit',
	'/pages/order/subcart',
	'/pages/order/express/list',
	'/pages/order/express/detail',
	'/pages/order/comment/list',
	'/pages/order/comment/detail',
	'/pages/setting/index',
	'/pages/setting/mobile',
	'/pages/setting/bind',
	'/pages/setting/location/edit',
	'/pages/setting/location/index',
	'/pages/agent/index',
	'/pages/agent/client',
	'/pages/agent/invite',
	'/pages/agent/earnest',
	'/pages/agent/scan/index',
	'/pages/agent/scan/freight',
	'/pages/agent/scan/tracing',
	'/pages/agent/scan/record',
	'/pages/agent/inventory/list',
	'/pages/agent/inventory/detail',
	'/pages/agent/inventory/deliver',
	'/pages/agent/order/cart',
	'/pages/agent/order/list',
	'/pages/agent/order/detail',
	'/pages/wallet/index',
	'/pages/wallet/income',
	'/pages/wallet/record',
	'/pages/wallet/transfer',
	'/pages/wallet/transfer/index',
	'/pages/wallet/transfer/record',
	'/pages/wallet/withdraw/index',
	'/pages/wallet/withdraw/record',
	'/pages/wallet/recharge/index',
	'/pages/wallet/recharge/record',
	'/pages/wallet/exchange',
	'/pages/wallet/setting/index',
	'/pages/wallet/setting/wechat',
	'/pages/wallet/setting/bankcard',
	'/pages/wallet/setting/alipay',
	'/pages/invest/record',
	'/pages/wallet/earnings',
	'/pages/playlet/follow',
	'/pages/playlet/detail',
	'/pages/shop/apply',
	'/pages/shop/auth/index',
	'/pages/shop/auth/company',
	'/pages/shop/auth/person',
	'/pages/services/cert',
	'/pages/intracity/order/list',
	'/pages/intracity/order/detail',
];

export const needMerchLogin = [
	'/pages/shop/manage/index',
	'/pages/shop/manage/edit',
	'/pages/shop/manage/menu',
	'/pages/shop/manage/feedback',
	'/pages/shop/order/list',
	'/pages/shop/order/detail',
	'/pages/shop/order/address',
	'/pages/shop/order/send',
	'/pages/shop/school/index',
	'/pages/shop/school/detail',
	'/pages/shop/goods/manage',
	'/pages/shop/dispatch/edit',
	'/pages/shop/dispatch/list',
	'/pages/shop/address/edit',
	'/pages/shop/address/list',
	'/pages/shop/setting/index',
	'/pages/shop/redemption',
	'/pages/shop/comment/list',
	'/pages/shop/comment/detail',
	'/pages/shop/yop/apply',
	'/pages/shop/yop/detail',
	'/pages/goods/edit/entrance',
	'/pages/goods/edit/cate',
	'/pages/goods/edit/intracity',
	'/pages/goods/edit/tire',
	'/pages/goods/edit/normal',
];
